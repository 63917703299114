import React, { useMemo } from 'react';
import {
  ApplyToJobModal,
  DetailedDrawer,
  MatchAcceptanceModal,
  ZeroState,
} from '@/client/components';
import CandidateMatchesJobCard from '@/client/cards/CandidateMatchesJobCard';
import { Job } from '@/client/components/DetailedDrawer/components/Job';
import { CardButton } from '@/client/cards/CandidateMatchesJobCard/components';
import { CircularProgress } from '@material-ui/core';
import { useDrawerDetailes } from '@/client/hooks';
import { generatePayment, generateGeneralPayment } from '@/utils';
import { getDatePSTFormat } from '@/client/utils';
import { candidates } from '@/client/constants';
import useStyles from './styles';
import useMyMatches from './useMyMatches';

const CandidateMyMatches = () => {
  const classes = useStyles();
  const { detailedId, isDrawerOpen, setIsDrawerOpen, closeDrawer, onOpenDetails } =
    useDrawerDetailes();
  const {
    isMatchAcceptanceModalOpen,
    candidateMatches,
    isLoading,
    isVerified,
    applyToJobId,
    onMatchAcceptanceModalClose,
    clearApplyToJobId,
    jobSelector,
    onApproveMatchForCandidate,
    onApply,
    onRejectMatch,
  } = useMyMatches();

  const pageContent = useMemo(() => {
    if (!isVerified) {
      return (
        <ZeroState
          text={`Get matched with compatible jobs when your profile is approved!\nOur team reviews all profiles within 24 hours of submission.`}
        />
      );
    }

    if (isLoading) {
      return <CircularProgress className={classes.loader} />;
    }

    if (!candidateMatches.length) {
      return (
        <>
          <ZeroState text="We are generating matches for you. Please check back later!" />
        </>
      );
    }

    return (
      <div className={classes.wrapper}>
        {candidateMatches.map((job) => (
          <CandidateMatchesJobCard
            key={job.id}
            className={classes.jobCard}
            data={{
              isMatch: true,
              payment:
                job.salaryFrom !== null && job.salaryTo !== null
                  ? generatePayment(job.salaryFrom, job.salaryTo)
                  : null,
              paymentPerYear: generateGeneralPayment(job.salaryFrom, job.salaryTo, 'year'),
              title: job.jobTitle,
              description: job.aboutJob,
              companyName: job.companyInfoName,
              workType: job.isRemote ? 'Remote' : 'Not Remote',
              date: job.publishedAt
                ? `Posted ${getDatePSTFormat(job.publishedAt, 'MMM dd, yyyy')}`
                : '',
              logoUri: job.companyInfoLogo ?? '/images/default-logo.svg',
              jobId: job.id,
            }}
            actionComponents={
              <>
                {job.isMatchConfirmationRequired ? (
                  <CardButton onClick={onApproveMatchForCandidate(job.id)} label="I'm Interested" />
                ) : (
                  <CardButton
                    onClick={job.isApplied ? undefined : onApply(job.id)}
                    label={job.isApplied ? 'Applied' : 'Apply'}
                    disabled={job.isApplied}
                  />
                )}
                <CardButton
                  label="Decline"
                  onClick={onRejectMatch(job.id)}
                  variant="outlined"
                  className={classes.rejectButton}
                />
              </>
            }
            responsiveActions={job.isMatchConfirmationRequired}
            compensationLabelInfo={candidates.salaryPositionFullTimePerYearInfo}
            onCardClick={onOpenDetails({ id: job.id, pageType: 'jobs' })}
          />
        ))}
        <ApplyToJobModal
          jobId={applyToJobId}
          jobSelector={jobSelector}
          onClose={clearApplyToJobId}
        />
        <DetailedDrawer
          isDrawerOpen={isDrawerOpen}
          onDrawerStateChange={setIsDrawerOpen}
          showOnlyOnDesktop
        >
          {detailedId && (
            <Job
              jobId={detailedId}
              closeDrawer={closeDrawer}
              button={{
                label: 'Apply',
                onClick: onApply(detailedId),
                disabled: candidateMatches.some(
                  (value) => value.id === detailedId && value.isApplied,
                ),
              }}
            />
          )}
        </DetailedDrawer>
      </div>
    );
  }, [
    applyToJobId,
    candidateMatches,
    classes.jobCard,
    classes.loader,
    classes.rejectButton,
    classes.wrapper,
    clearApplyToJobId,
    closeDrawer,
    detailedId,
    isDrawerOpen,
    isLoading,
    isVerified,
    jobSelector,
    onApply,
    onApproveMatchForCandidate,
    onOpenDetails,
    onRejectMatch,
    setIsDrawerOpen,
  ]);
  return (
    <>
      {pageContent}
      <MatchAcceptanceModal
        isOpen={isMatchAcceptanceModalOpen}
        onClose={onMatchAcceptanceModalClose}
      />
    </>
  );
};

export default CandidateMyMatches;
