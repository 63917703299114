import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(5),
    },
  },
  maxWidth: {
    maxWidth: 'none',
  },
}));

export default useStyles;
