import { makeStyles } from '@material-ui/core';
import { CARD_CONTAINER_SPACING } from '@/client/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 16),
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(30),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md - 2 * CARD_CONTAINER_SPACING.md,
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.values.lg - 2 * CARD_CONTAINER_SPACING.lg,
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(40),
    },
  },
  jobCard: {
    width: '100%',
  },
  loader: {
    margin: '0 auto',
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(12),
    },
  },
  rejectButton: {
    color: `${theme.palette.error.main}!important`,
    borderColor: `${theme.palette.border}!important`,
    '&:hover, &:focus': {
      borderColor: theme.palette.border,
    },
  },
}));

export default useStyles;
