import { SuccessModalView } from '@/client/components/Modals';
import { Modal } from '@/client/components';
import useStyles from './styles';
import { IMatchAcceptanceModalProps } from './types';

const MatchAcceptanceModal = ({ isOpen, onClose }: IMatchAcceptanceModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={onClose} classes={{ paper: classes.paper }}>
      <SuccessModalView
        onClose={onClose}
        onDone={onClose}
        title="Great! We'll notify you if the employer requests an interview"
      />
    </Modal>
  );
};

export default MatchAcceptanceModal;
