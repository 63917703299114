import {
  loadMatchesForCandidate,
  rejectMatchForCandidate,
  approveMatchForCandidate,
} from '@/client/redux/actions';
import {
  candidateMatchByIdSelector,
  matchesForCandidateSelector,
  userVerifiedSelector,
} from '@/client/redux/selectors';
import { clearMatches } from '@/client/redux/store/matches.slice';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useMyMatches = () => {
  const [applyToJobId, setApplyToJobId] = useState<string | null>(null);
  const [isMatchAcceptanceModalOpen, setMatchAcceptanceModalOpen] = useState(false);
  const { candidateMatches, isLoading } = useSelector(matchesForCandidateSelector);
  const isVerified = useSelector(userVerifiedSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVerified) {
      dispatch(loadMatchesForCandidate());
    }
    return () => {
      dispatch(clearMatches());
    };
  }, [dispatch, isVerified]);

  const onMatchAcceptanceModalClose = useCallback(() => {
    setMatchAcceptanceModalOpen(false);
  }, []);

  const onApproveMatchForCandidate = useCallback(
    (jobId: string) => () => {
      setMatchAcceptanceModalOpen(true);
      dispatch(approveMatchForCandidate(jobId));
    },
    [dispatch],
  );

  const onApply = useCallback(
    (jobId: string) => () => {
      setApplyToJobId(jobId);
    },
    [],
  );
  const clearApplyToJobId = useCallback(() => {
    setApplyToJobId(null);
  }, []);
  const jobSelector = useCallback(() => candidateMatchByIdSelector(applyToJobId), [applyToJobId]);

  const onRejectMatch = useCallback(
    (jobId: string) => () => {
      dispatch(rejectMatchForCandidate(jobId));
    },
    [dispatch],
  );

  return {
    isMatchAcceptanceModalOpen,
    candidateMatches,
    isLoading,
    isVerified,
    applyToJobId,
    onMatchAcceptanceModalClose,
    clearApplyToJobId,
    jobSelector,
    onApproveMatchForCandidate,
    onApply,
    onRejectMatch,
  };
};

export default useMyMatches;
