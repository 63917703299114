const SALARY_CANDIDATE_FULL_TIME_PER_MONTH =
  "Estimated base salary for the candidate's full-time work, per month. You may directly negotiate this rate with the candidate if you decided to give an offer.";
const SALARY_CANDIDATE_FULL_TIME_PER_YEAR =
  "Estimated base salary for the candidate's full-time work, per year";
const SALARY_POSITION_FULL_TIME_PER_YEAR =
  'Estimated base salary for this position, if full-time, per year';

export default {
  salaryCandidateFullTimePerMonthInfo: SALARY_CANDIDATE_FULL_TIME_PER_MONTH,
  salaryCandidateFullTimePerYearInfo: SALARY_CANDIDATE_FULL_TIME_PER_YEAR,
  salaryPositionFullTimePerYearInfo: SALARY_POSITION_FULL_TIME_PER_YEAR,
};
