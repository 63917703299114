import {
  AdminRedirect,
  CandidateMyMatches,
  EmployerRedirect,
  MainContentWrapper,
} from '@/client/components/';
import { userSelector } from '@/client/redux/selectors';
import { UserRole } from '@/enums';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const user = useSelector(userSelector);

  const content = useMemo(() => {
    if (user?.role == null) {
      return null;
    }
    const roleComponents = {
      [UserRole.AdminEmployer]: <EmployerRedirect />,
      [UserRole.MasterEmployer]: <EmployerRedirect />,
      [UserRole.Employer]: <EmployerRedirect />,
      [UserRole.Candidate]: <CandidateMyMatches />,
      [UserRole.Admin]: <AdminRedirect />,
    };
    return roleComponents[user.role];
  }, [user?.role]);

  return (
    <MainContentWrapper
      className={clsx(classes.container, {
        [classes.maxWidth]:
          user?.role &&
          [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer].includes(user.role),
      })}
    >
      {content}
    </MainContentWrapper>
  );
};

export default Home;
