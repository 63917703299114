import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const AdminRedirect = () => {
  const router = useRouter();

  useEffect(() => {
    router.push('/admin/jobs');
  }, [router]);

  return null;
};

export default AdminRedirect;
