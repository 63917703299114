import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(15, 8),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 14),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(25),
    },
  },
}));
export default useStyles;
